/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:510adeb1-65fa-4715-8c61-fd2689e5f0cc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QlmDKpHxc",
    "aws_user_pools_web_client_id": "2ham4scrn8nlhk28apqilpiquc",
    "oauth": {
        "domain": "datasucker-test.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://test.dataupload.ness.com/",
        "redirectSignOut": "https://test.dataupload.ness.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dataSuckerMeta-test",
            "region": "us-east-1"
        },
        {
            "tableName": "data-sucker-meta-test",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "datasucker-storage163014-test",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://87jrgsbls4.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "VersionsAPI",
            "endpoint": "https://zh3832gypa.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "CalendarRegionsAPI",
            "endpoint": "https://c8mxgw4r94.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "datasucker",
            "endpoint": "https://foqcb8zyyg.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
